import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete,
  Edit,
  ExpandMore,
  Link,
  Visibility,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropTipoRateio } from 'utils/drops';
import {
  checkInfosProduto,
  summarizer,
  encryptURL,
  round,
} from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog, useModal } from 'components/Modals';
import HistoricoCompraModal from 'components/Modals/HistoricoCompraModal';
import CatalogoModal from 'components/Modals/CatalogoModal';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import ButtonMenu from 'components/ButtonMenu';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import Blank from 'assets/blank.png';
import styles from '../styles';

const Itens = ({
  drops,
  onSubmit,
  onDelete,
  documento = {},
  disableActions,
  onSubmitImposto,
  onSubmitCfo,
}) => {
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const rotina = 'DocumentoItem';

  const equals = (a = [], b = []) =>
    a.length === b.length && a.every((v, i) => v === b[i]);

  const onSelectAll = () => {
    if (equals(selected, documento?.DocumentoItems)) {
      setSelected([]);
    } else {
      setSelected(documento?.DocumentoItems);
    }
  };

  const Modal = ({ item }) => {
    const [additionals, setAdditionals] = useState(null);
    const [additionalLoading, setAdditionalsLoading] = useState(false);
    const { postLoading } = useContext(DocumentosContext);
    const defaultValues = {
      id: item?.id,
      documento_id: documento?.id,
      produto_id: item?.produto_id || null,
      descricao: item?.descricao || '',
      quantidade: item?.quantidade || '',
      unidade_medida_id: item?.unidade_medida_id || null,
      referencia: item?.referencia || '',
      preco: item?.preco || '',
      desconto1_pc: item?.desconto1_pc || '',
      desconto_vlr: item?.desconto_vlr || '',
      przent: item?.przent || null,
      planoconta_id: item?.planoconta_id || null,
      ccusto_id: item?.ccusto_id || null,
      aplicacao_id: item?.aplicacao_id || null,
      frota_id: item?.frota_id || null,
      infadc: item?.infadc || '',
    };
    const { control, handleSubmit, setValue, watch } = useForm({
      defaultValues,
    });

    const getTotal = () =>
      (watch('quantidade') || 1) * watch('preco') + watch('desconto_vlr');

    useEffect(() => {
      const desconto_vlr =
        watch('desconto1_pc') * watch('preco') * watch('quantidade');
      setValue(
        'desconto_vlr',
        desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
      );
    }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

    const itemOptions = [
      {
        name: 'Histórico de Compras do Fornecedor',
        icon: 'work_history',
        action: () => {
          if (Boolean(documento?.cadastro_id)) {
            openDialog(
              <HistoricoCompraModal
                cadastro_id={documento?.cadastro_id}
                onSubmit={(data) => {
                  if (Boolean(data?.length)) {
                    onSubmit({
                      Itens: data?.map((d) => ({
                        ...d,
                        documento_id: documento?.id,
                      })),
                    });
                  }
                }}
              />,
              'Histórico de Compras do Fornecedor'
            );
          } else {
            toastWarning('Documento não possui entidade');
          }
        },
      },
      {
        name: 'Histórico de Compras do Produto',
        icon: 'work_history',
        action: () => {
          if (Boolean(watch('produto_id'))) {
            openDialog(
              <HistoricoCompraModal
                produto_id={watch('produto_id')}
                onSubmit={(data) => {
                  if (Boolean(data?.length)) {
                    onSubmit({
                      Itens: data?.map((d) => ({
                        ...d,
                        documento_id: documento?.id,
                      })),
                    });
                  }
                }}
              />,
              'Histórico de Compras do Produto'
            );
          } else {
            toastWarning('Selecione um Produto');
          }
        },
      },
      {
        name: 'Catálogo de Produtos',
        icon: 'balance',
        action: () =>
          openDialog(
            <CatalogoModal
              onSubmit={(data) => {
                if (Boolean(data?.length)) {
                  onSubmit({
                    Itens: data?.map((d) => ({
                      produto_id: d?.id,
                      descricao: d?.descricao,
                      quantidade: d?.quantidade || 1,
                      referencia: d?.referencia,
                      unidade_medida_id: d?.unidade_medida_id,
                      preco: d?.custo || 0,
                      documento_id: documento?.id,
                    })),
                  });
                }
                closeModal();
              }}
              cadastro_id={documento?.cadastro_id}
            />,
            'Catálogo de Produtos'
          ),
      },
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            {!Boolean(item) &&
              (Boolean(documento?.Especie?.natureza === 'COMPRA') ||
                Boolean(documento?.Especie?.natureza === 'ORCAMENTO')) && (
                <Grid item xs={12} textAlign="center">
                  <ButtonMenu
                    buttonProps={{ variant: 'outlined' }}
                    options={itemOptions}
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <VirtualDrop
                busca_refinada
                name="produto_id"
                control={control}
                label="Produto"
                options={drops?.Produto}
                onValueChange={async (p) => {
                  setAdditionals(null);
                  if (
                    p?.value &&
                    !(Boolean(item?.id) && !Boolean(item?.produto_id))
                  ) {
                    try {
                      setAdditionalsLoading(true);
                      const produto = await checkInfosProduto({
                        disponivel: 'SIM',
                        produto_id: p?.value,
                        filial_id: documento?.filial_id,
                        cadastro_id: documento?.cadastro_id,
                      });
                      setValue('descricao', produto?.descricao);
                      setValue('quantidade', 1);
                      setValue('unidade_medida_id', produto?.unidade_medida_id);
                      setValue('referencia', produto?.referencia);
                      setValue('preco', produto?.custo);
                      setAdditionals({
                        saldo: produto?.saldo,
                        referencia: produto?.referencia,
                        localizador: produto?.localizador,
                        imagem: produto?.imagem,
                      });
                    } catch (error) {
                    } finally {
                      setAdditionalsLoading(false);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input name="descricao" control={control} label="Descrição" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="quantidade"
                control={control}
                label="Quantidade"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="unidade_medida_id"
                control={control}
                label="Unidade de Medida"
                options={drops?.UnidadeMedida}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input name="referencia" control={control} label="Referência" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="desconto1_pc"
                control={control}
                label="% Desconto"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="preco"
                control={control}
                label="Preço Unitário"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="przent"
                control={control}
                label="Prazo de Entrega"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="planoconta_id"
                control={control}
                label="Plano de Conta"
                options={drops?.Planoconta?.filter(
                  (p) => p?.operacional === 'SIM'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="ccusto_id"
                control={control}
                label="Centro de Custo"
                options={drops?.Ccusto}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="aplicacao_id"
                control={control}
                label="Aplicação"
                options={drops?.Aplicacao}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="frota_id"
                control={control}
                label="Frota"
                options={drops?.Frota}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="infadc"
                control={control}
                label="Informações Adicionais"
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div" align="center">
                Total:{' '}
                {getTotal()?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {Boolean(watch('produto_id')) && Boolean(additionals) && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  component="img"
                  sx={styles.image}
                  src={additionals?.imagem || Blank}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Saldo: {additionals?.saldo || '0'}{' '}
                  {additionals?.UnidadeMedida?.descricao || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Referência: {additionals?.referencia || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="div" align="center">
                  Localização: {additionals?.localizador || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Itens: [data] }))}
            loading={postLoading || additionalLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const MultiModal = ({ itens = [] }) => {
    const { postLoading } = useContext(DocumentosContext);
    const defaultValues = {
      przent: null,
      pedcomp: null,
      planoconta_id: null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    const onConfirm = ({ przent, pedcomp, planoconta_id }) => {
      const Itens = itens?.map((i) => ({
        id: i?.id,
        documento_id: i?.documento_id,
        ...(Boolean(przent) && { przent }),
        ...(Boolean(pedcomp) && { pedcomp }),
        ...(Boolean(planoconta_id) && { planoconta_id }),
      }));

      onSubmit({ Itens });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Produtos Selecionados</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {itens?.map((i) => (
                <Box my={1}>
                  <Typography variant="caption">
                    • {i?.referencia} - {i?.descricao}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="przent"
            control={control}
            label="Prazo de Entrega"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano de Conta"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input name="pedcomp" control={control} label="Pedido de Compra" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onConfirm)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const AverageModal = ({ type }) => {
    const { postLoading } = useContext(DocumentosContext);
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues: { type: type || null, percentual: '', valor: '' },
    });

    useEffect(() => {
      if (Boolean(watch('percentual'))) {
        const valor = (documento?.Totais?.preco * watch('percentual')) / 100;
        setValue('valor', valor);
      }
    }, [watch('percentual')]);

    const onConfirm = ({ type, valor }) => {
      const isMargem =
        type === 'internacionalizacao' ||
        type === 'fretend' ||
        type === 'segurond' ||
        type === 'impostond' ||
        type === 'comissaond';
      if (!Boolean(type)) {
        return toastWarning('Selecione o tipo');
      }

      let valor_lancado = 0;
      const Itens = documento?.DocumentoItems?.map((i, idx) => {
        const precotot = i?.preco * i?.quantidade;
        const total = summarizer(
          documento?.DocumentoItems?.map((d) => ({
            total: d?.preco * d?.quantidade,
          })),
          'total'
        );
        const average = round((valor * precotot) / total);
        const value = round(
          idx + 1 === documento?.DocumentoItems?.length
            ? round(valor - valor_lancado)
            : average
        );

        valor_lancado = round(valor_lancado + average);
        if (isMargem) {
          return {
            id: i?.id,
            documento_id: i?.documento_id,
            Margem: {
              documento_item_id: i?.DocumentoItemMc?.documento_item_id,
              [type]: value,
            },
          };
        } else {
          return { id: i?.id, documento_id: i?.documento_id, [type]: value };
        }
      });
      onSubmit({ Itens });
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="type"
            control={control}
            label="Tipo de Divisão"
            options={dropTipoRateio}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="percentual" control={control} label="Percentual" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="valor" control={control} label="Valor" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const options = [
    {
      name: 'Adicionar Item',
      icon: 'add',
      action: () => openDialog(<Modal />, 'Adicionar Item'),
    },
    {
      name: 'Editar Selecionados',
      icon: 'edit',
      action: () => {
        if (Boolean(selected?.length)) {
          openDialog(<MultiModal itens={selected} />, 'Editar Itens');
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Recarregar Tributos',
      icon: 'refresh',
      action: () => {
        if (Boolean(selected?.length)) {
          onSubmitImposto(selected.map((s) => s?.id));
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Recarregar CFOP',
      icon: 'refresh',
      action: () => {
        if (Boolean(selected?.length)) {
          onSubmitCfo(selected.map((s) => s?.id));
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
    {
      name: 'Outros Valores',
      icon: 'splitscreen',
      show: Boolean(documento?.DocumentoItems?.length),
      action: () => {
        if (Boolean(documento?.DocumentoItems?.length)) {
          openDialog(<AverageModal />, 'Outros Valores');
        } else {
          toastWarning('Documento não possui item');
        }
      },
    },
    {
      name: 'Formação de Preço',
      icon: 'price_change',
      show: Boolean(documento?.Especie?.natureza === 'FATURA'),
      action: () =>
        navigate(`/app/Documento/FormacaoDePreco`, {
          state: { id: documento?.id },
        }),
    },
  ];

  const Line = ({ value, label, type }) => (
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center">
        {Boolean(type) ? (
          <Typography
            sx={disableActions ? styles?.lineLabel : styles?.lineLabelLink}
            variant="subtitle2"
            onClick={() =>
              !disableActions &&
              openDialog(<AverageModal type={type} />, 'Outros Valores')
            }
          >
            {label}:
          </Typography>
        ) : (
          <Typography sx={styles?.lineLabel} variant="subtitle2">
            {label}:
          </Typography>
        )}
        <Typography sx={styles?.lineValue} variant="body2">
          {value}
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          {!disableActions && (
            <Grid item xs={12} textAlign="center">
              <ButtonMenu
                buttonProps={{ variant: 'outlined' }}
                options={options}
              />
            </Grid>
          )}
          {!!documento?.DocumentoItems?.length && (
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={equals(selected, documento?.DocumentoItems)}
                          onChange={onSelectAll}
                        />
                      </TableCell>
                      <TableCell>Sequencia</TableCell>
                      <TableCell>Código do Produto</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Referência</TableCell>
                      <TableCell>Referência do Fornecedor</TableCell>
                      {!disableActions && <TableCell>Saldo</TableCell>}
                      <TableCell>Quantidade</TableCell>
                      <TableCell>Preço</TableCell>
                      <TableCell>Desconto</TableCell>
                      <TableCell>Valor Total</TableCell>
                      <TableCell>Origem</TableCell>
                      {Boolean(
                        documento?.NaturezaOperacao?.vinculo === 'SIM'
                      ) && <TableCell align="center">Vínculo</TableCell>}
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documento?.DocumentoItems?.map((item) => {
                      const origem = {
                        value: Boolean(item?.DocumentoItemOrigems?.length)
                          ? item?.DocumentoItemOrigems[0]?.documento_origem ||
                            'Nº não informado'
                          : null,
                        hint: item?.DocumentoItemOrigems[0]?.especie_origem,
                      };
                      const vinculo = {
                        value: Boolean(item?.Vinculados?.length)
                          ? item?.Vinculados[0]?.documento_vinculado ||
                            'Nº não informado'
                          : null,
                        hint: item?.Vinculados[0]?.especie_vinculado,
                      };
                      const isVinculo =
                        documento?.NaturezaOperacao?.vinculo === 'SIM';
                      let qtgerada = 0;
                      (Boolean(isVinculo)
                        ? item?.Vinculados || []
                        : item?.DocumentoItemOrigems || []
                      )?.map((m) => (qtgerada += m?.quantidade || 0));
                      const quantidade =
                        (item?.quantidade || 0) - (qtgerada || 0);
                      return (
                        <TableRow key={item?.id?.toString()}>
                          <TableCell>
                            <Checkbox
                              color="primary"
                              checked={selected?.includes(item)}
                              onChange={() => {
                                if (selected?.includes(item)) {
                                  setSelected(
                                    selected?.filter((s) => item !== s)
                                  );
                                } else {
                                  if (Boolean(item?.kit_id)) {
                                    setSelected([
                                      ...selected?.filter(
                                        (f) => f?.kit_id !== item?.kit_id
                                      ),
                                      ...documento?.DocumentoItems?.filter(
                                        (f) => f?.kit_id === item?.kit_id
                                      ),
                                    ]);
                                  } else {
                                    setSelected([...selected, item]);
                                  }
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>{item?.sequencia}</TableCell>
                          <TableCell>{item?.produto_id}</TableCell>
                          <TableCell>{item?.descricao}</TableCell>
                          <TableCell>{item?.Produto?.referencia}</TableCell>
                          <TableCell>{item?.referencia}</TableCell>
                          {!disableActions && (
                            <TableCell>{item?.saldo}</TableCell>
                          )}
                          <TableCell>
                            {item?.quantidade} {item?.UnidadeMedida?.sigla}
                          </TableCell>
                          <TableCell>
                            {(item?.preco * 1)?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {item?.desconto_vlr?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {(
                              item?.preco * item?.quantidade +
                              item?.desconto_vlr
                            )?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            {Boolean(origem?.hint) ? (
                              <Tooltip placement="left" title={origem?.hint}>
                                <Typography variant="body2">
                                  {origem?.value}
                                </Typography>
                              </Tooltip>
                            ) : (
                              origem?.value
                            )}
                          </TableCell>
                          {Boolean(
                            documento?.NaturezaOperacao?.vinculo === 'SIM'
                          ) && (
                            <TableCell align="center">
                              {Boolean(vinculo?.hint) ? (
                                <Tooltip placement="left" title={vinculo?.hint}>
                                  <Typography variant="body2">
                                    {vinculo?.value}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                vinculo?.value
                              )}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {!disableActions && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <Modal item={item} />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                            )}
                            {!disableActions && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  onDelete({ rotina, id: item?.id })
                                }
                              >
                                <Delete />
                              </IconButton>
                            )}
                            <IconButton
                              size="small"
                              onClick={() =>
                                navigate(`/app/Documento/Item`, {
                                  state: { item, disableActions },
                                })
                              }
                            >
                              <Visibility />
                            </IconButton>
                            {!disableActions && Boolean(quantidade > 0) && (
                              <IconButton
                                size="small"
                                onClick={() => {
                                  const params = {
                                    id: item?.id,
                                    descricao: item?.descricao,
                                    um: item?.UnidadeMedida?.sigla,
                                    documento_id: documento?.id,
                                    cadastro_id: documento?.cadastro_id,
                                    filial_id: documento?.filial_id,
                                    vinculo: isVinculo,
                                    quantidade,
                                  };
                                  navigate(
                                    `/app/Documento/Vinculo/${encryptURL(
                                      params
                                    )}`
                                  );
                                }}
                              >
                                <Link />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Card>
      <Card title="Totais" style={styles?.card}>
        <Grid container spacing={2}>
          <Line value={documento?.Totais?.peso || 0} label="Peso" />
          <Line value={documento?.Totais?.volume || 0} label="Volume" />
          <Line
            value={(documento?.Totais?.preco || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Preço"
          />
          <Line
            value={(documento?.Totais?.frete || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Frete"
            type="frete"
          />
          <Line
            value={(documento?.Totais?.desconto || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Desconto"
            type="desconto_vlr"
          />
          <Line
            value={(documento?.Totais?.seguro || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Seguro"
            type="seguro"
          />
          <Line
            value={(documento?.Totais?.despac || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Despesas Acessórias"
            type="despac"
          />
          <Line
            value={(documento?.Totais?.outros || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Outros"
            type="outros"
          />
          <Line
            value={(documento?.Totais?.eventuais || 0)?.toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            )}
            label="Eventuais"
            type="eventuais"
          />
          <Line
            value={(documento?.Totais?.impostos || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
            label="Impostos"
          />
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Total:{' '}
              {(documento?.Totais?.documento || 0)?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Itens;
