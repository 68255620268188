import React from 'react';
import { Grid } from '@mui/material';
import { dropStatusDocumento } from 'utils/drops';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';

const Filter = ({ grid, control, drops, user }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('situacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="situacao"
            control={control}
            label="Situação"
            options={drops?.StatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('descricao') && (
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
      )}
      {checkDisplay('referencia') && (
        <Grid item xs={12}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
      )}
      {checkDisplay('quantidade') && (
        <Grid item xs={12}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
      )}
      {checkDisplay('unidade_medida') && (
        <Grid item xs={12}>
          <Dropdown
            name="unidade_medida"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('preco') && (
        <Grid item xs={12}>
          <InputMask name="preco" control={control} label="Preço" />
        </Grid>
      )}
      {checkDisplay('requisitante') && (
        <Grid item xs={12}>
          <Dropdown
            name="requisitante"
            control={control}
            label="Requisitante"
            options={drops?.Users}
            multiple
            disabled={user?.permissoes?.block_compras_requisicoes_grid}
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input
            name="documento"
            control={control}
            label="Nº do Documento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('filial') && (
        <Grid item xs={12}>
          <Dropdown
            name="filial"
            control={control}
            label="Filial"
            options={drops?.Filial}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('cegrupo_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="cegrupo_id"
            control={control}
            label="Grupo"
            options={drops?.GrupoProduto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('celinha_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="celinha_id"
            control={control}
            label="Linha"
            options={drops?.LinhaProduto}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('cemarca_id') && (
        <Grid item xs={12}>
          <Dropdown
            name="cemarca_id"
            control={control}
            label="Marca"
            options={drops?.Marca}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('historico') && (
        <Grid item xs={12}>
          <Input name="historico" control={control} label="Histórico" />
        </Grid>
      )}
      {checkDisplay('especificacao') && (
        <Grid item xs={12}>
          <Input name="especificacao" control={control} label="Especificação" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
