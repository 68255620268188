import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { summarizer } from 'utils/functions';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';

const Etiquetas = () => {
  const { state } = useLocation();
  const { drops } = useContext(DropsContext);
  const [etiquetas, setEtiquetas] = useState([]);
  const { postLoading, postEtiquetas } = useContext(ProdutosContext);
  const { getDocumentoItens, documentoItens, getLoading } =
    useContext(DocumentosContext);
  const { control, handleSubmit, watch, resetField } = useForm({
    defaultValues: { impressora_id: null, modelo_id: null },
  });

  const onCellEditCommit = (e) => {
    const editedRows = etiquetas?.map((c) => {
      if (c?.id === e?.id) {
        return { ...c, [e?.field]: e?.value };
      } else {
        return { ...c };
      }
    });
    setEtiquetas(editedRows);
  };

  const onSubmit = (values) => {
    postEtiquetas({
      data: {
        ...values,
        produtos: etiquetas
          ?.filter((f) => Boolean(f?.quantidade))
          ?.map((e) => ({
            produto_id: e?.produto_id,
            variacao_id: e?.variacao_id,
            quantidade: e?.quantidade,
          })),
      },
    });
  };

  useEffect(() => {
    getDocumentoItens(state?.notas);
  }, []);

  useEffect(() => {
    const renderEtiquetas = [];
    documentoItens
      ?.filter((f) => Boolean(f?.produto_id))
      ?.map((di) => {
        const quantidade =
          (di?.quantidade || 0) -
            summarizer(di?.DocumentoItemVariacaos, 'quantidade') || 0;
        if (quantidade > 0) {
          renderEtiquetas?.push({
            quantidade,
            descricao: di?.descricao,
            referencia: di?.referencia,
            produto_id: di?.produto_id,
            localizador: di?.Produto?.localizador,
          });
        }
        di?.DocumentoItemVariacaos?.map((v) => {
          renderEtiquetas?.push({
            descricao: di?.descricao,
            referencia: di?.referencia,
            produto_id: di?.produto_id,
            quantidade: v?.quantidade,
            variacao: v?.variacao,
            variacao_id: v?.variacao_id,
          });
        });
      });
    setEtiquetas(renderEtiquetas?.map((e, id) => ({ ...e, id })));
  }, [documentoItens]);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Imprimir Etiquetas" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="impressora_id"
              control={control}
              label="Impressora"
              options={drops?.Impressoraweb}
              onValueChange={() => resetField('modelo_id')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="modelo_id"
              control={control}
              label="Modelo"
              options={drops?.EtiquetaModelo?.filter(
                (f) => f?.impressoraweb_id === watch('impressora_id')
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={etiquetas}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Produto',
                  flex: 5,
                  sortable: false,
                },
                {
                  field: 'variacao',
                  headerName: 'Variação',
                  flex: 3,
                  sortable: false,
                },
                {
                  field: 'localizador',
                  headerName: 'Localizador',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 2,
                  editable: true,
                  sortable: false,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Imprimir
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Etiquetas;
